import React, { useState, useEffect, useRef } from 'react'
import './app.css'
import Layout from '../components/layout'

function useInterval(callback: any, delay: any) {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const images = [
  'https://images.ctfassets.net/6ag9zos91onm/3mG2KwmaoU8B9kxbpkfqN6/9b6cc3e1c9c6ef5ddb75f1459b5cd3f7/0.jpg',
  'https://images.ctfassets.net/6ag9zos91onm/3VTlWu0DZoyOMjpFxeVaYV/49a4d10d72885e20d6553ede38a01598/1.jpg',
  'https://images.ctfassets.net/6ag9zos91onm/4CnzJzbrXR4yG0fGHLdDzV/08ab889650f3828538cad9cca4694ffd/2.jpg',
]

function Carousel() {
  const [item, setItem] = useState(0)

  useInterval(() => {
    // Your custom logic here
    console.log(item)
    const next = item > 1 ? 0 : item + 1
    setItem(next)
  }, 5000)
  return (
    <div
      id="carousel"
      className="carousel fade"
      data-ride="carousel"
      data-interval="100"
    >
      <div className="carousel-inner">
        {images.map((image, index) => (
          <div
            className={
              index === item ? 'carousel-item active' : 'carousel-item'
            }
            key={index}
          >
            <img src={image} />
          </div>
        ))}
      </div>
    </div>
  )
}

const AppPage = () => {
  return (
    <Layout
      locale="et"
      page={{
        slug: 'app',
        metaTitle: 'Biitsi App for Teras',
        metaDescription:
          'Reserve, Invite, Play. Get playing partners, register for open shifts.',
      }}
    >
      <div>
        <div className="dark">
          <div className="blurred-cover" />
        </div>
        <main id="main">
          <section id="hero">
            <div className="container">
              <div className="row">
                <div className="app-content">
                  <h2 className="app-name">BIITSI APP FOR TERAS</h2>
                  <h1 className="app-tagline">Reserve, invite, play</h1>
                  <div className="app-description">
                    <p>Own shifts, open shifts, players chat</p>
                  </div>
                  <div className="app-links">
                    <a
                      className="app-link"
                      id="appleLink"
                      href="https://itunes.apple.com/us/app/biitsi/id1436428978?ls=1&amp;mt=8"
                    >
                      <img
                        className="app-icon"
                        src="https://images.ctfassets.net/pnyokv5ndhzc/22hTgOQImln0ty5o3TttAC/3881c841014dc3b7cece530a58e30152/badgeappstore.png"
                      />
                    </a>
                    <a
                      className="app-link"
                      id="googleLink"
                      href="https://play.google.com/store/apps/details?id=fi.biitsi.app"
                    >
                      <img
                        className="app-icon"
                        src="https://images.ctfassets.net/pnyokv5ndhzc/7nIk20f1bBfR0Uw8agWlrg/8af7f1e29c11fb316be5449b7f8db111/badgegoogleplay.png"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer id="footer">
            <div className="phone-container container">
              <div className="phone-wrapper">
                <img
                  src="https://images.ctfassets.net/pnyokv5ndhzc/7cs1OoOSNZpT6DYv3grx5M/5eb017a1df1136c52bec3301448ad64b/phoneframe.png"
                  className="phone-frame"
                />
                <Carousel />
              </div>
            </div>
          </footer>
        </main>
      </div>
    </Layout>
  )
}

export default AppPage
